<template>
    <section id="content">

        <div class="content-wrap pb-0" style="padding: 0">
            <div class="section m-0 border-0 " style="padding: 80px 0;">
                <div class="container center clearfix">

                    <div class="heading-block">
                        <h2>Our advantages</h2>
                        <span>Transform your business with our customized mobile solutions and services!</span>
                    </div>
                    <div class="container">

                        <div class="card card-body">
                            <div class="row col-mb-50 mb-0">
                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-arrow-down-circle i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>WE PERFORM TASKS OF ANY LEVEL AND COMPLEXITY</h3>
                                            <p>Our team is ready to carry out the projects of any complexity, from the creation of simple landing pages, websites, mobile widgets, to major, large-scale projects.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center  fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-arrow-down-circle i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>WE ALWAYS COMPLETE
                                                THE PROJECTS LAUNCHED</h3>
                                            <p>You can always count on 100% completion of the project you ordered from us. You always get our support at all stages of cooperation.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-arrow-down-circle i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>WE WILL NOT FAIL YOU FOR TIMELINE</h3>
                                            <p>You can be sure that we will not fail you for the time limits specified in the contract and all tasks will be completed before the deadline</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-light fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-arrow-down-circle i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>INDIVIDUAL APPROACH TO EACH CLIENT</h3>
                                            <p>It often happens that there is no universal solution for every business. That is why we have an individual solution for all our clients.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-light fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-arrow-down-circle i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>HIGH QUALIFICATION OF OUR SPECIALISTS</h3>
                                            <p>Our team includes only high-level specialists to provide services to our clients at the highest level.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-light fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-arrow-down-circle i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>OPEN CONDITIONS OF COOPERATION</h3>
                                            <p>In order to nullify the situations of misunderstanding on both sides, all the details of cooperation are set in the contract.</p>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-dark fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-arrow-down-circle i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>INTEGRATED APPROACH TO EACH SERVICE</h3>
                                            <p>In addition to development of the mobile applications and websites, we provide services for their promotion on the Internet in order to get targeted users and visitors.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-dark fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-arrow-down-circle i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>CERTAINTY OF LONG-TERM COOPERATION</h3>
                                            <p>We always stand ready to assist in the creation or adaptation of new or already existing product, whether it pertains to a mobile application, website or company for promoting your product.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-4">
                                    <div class="feature-box fbox-center fbox-dark fbox-effect">
                                        <div class="fbox-icon">
                                            <a href="#"><i class="icon-line-arrow-down-circle i-alt"></i></a>
                                        </div>
                                        <div class="fbox-content">
                                            <h3>WE DEVELOP THE PROFITABLE APPLICATIONS AND WEBSITES</h3>
                                            <p>We know how applications and websites should look like / work to generate revenue.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
            <div class="section m-0 border-0 bg-color dark" style="padding: 80px 0;">
                <div class="container center clearfix">

                    <div class="heading-block">
                        <h2>Cooperation with us is</h2>
                    </div>

                    <div class="row justify-content-center col-mb-50 mb-0">
                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">1</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3>Officially registered company with its staff of highly qualified specialists<span class="subtitle">We conclude and work only under an official contract until the completion of services.</span></h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i>2</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3>Full and integrated development cycle of applications and websites<span class="subtitle">We develop your product from technical specifications and prototypes to posting / publishing on the Internet, App Store and Play Market.</span></h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">3</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3>We use any type of communication<span class="subtitle">We always keep in touch with the customer in any way convenient for him through communication for discussion and presentation.</span></h3>
                                </div>
                            </div>
                        </div>


                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">4</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3>Long-term experience in the IT field<span class="subtitle">We apply an extensive experience in information technologies to carry out projects of any complexity.</span></h3>
                                </div>
                            </div>
                        </div>


                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">5</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3>We develop the terms of reference (TR)<span class="subtitle">We create terms of reference and prototype as part of designing applications and websites.</span></h3>
                                </div>
                            </div>
                        </div>


                        <div class="col-sm-6 col-lg-4">
                            <div class="feature-box fbox-center fbox-bg fbox-outline fbox-dark fbox-effect">
                                <div class="fbox-icon">
                                    <a href="#"><i class="i-alt">6</i></a>
                                </div>
                                <div class="fbox-content">
                                    <h3>We create all turnkey projects<span class="subtitle">We develop mobile applications, websites and companies for their promotion with a backend for all platforms.</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="section border-top-0 m-0">
                <div class="container text-center">
                    <div class="heading-block center">
                        <h2>Why order from us?k</h2>
                    </div>
                    <div class="heading-block center">
                        <span>Development of mobile applications for iOS and Android</span>
                    </div>

                    <p>When choosing us, the customers receive a well-thought-out product that brings income or makes life easier for a huge number of people! Our team includes high-level specialists. We develop the applications for mobile devices based on iOS (iPhone/iPad) and Android platforms. Our team creates solutions for business, advertising applications, for education, medicine, sports, recreation, taxi services, commerce, design and other customized mobile products. As we begin the development of the project, we implement analytics and set the goals to be achieved using the application. A detailed analysis of the market, competitors, target audience, trend and experience in the concerned area provides an opportunity to clearly define the business problems, their solutions and the final result.</p>

                    <div class="heading-block center">
                        <span>Full cycle of turnkey development and technologies applied</span>
                    </div>

                    <p>Our company offers a full cycle of mobile application development! From creation of the concept, the writing of the terms of reference and the prototype to the publication of the project, its support and development. We apply advanced technologies to create the mobile applications: iOS-Swift, Xcode, MVC patterns, Android-Java, MVC/MVP, Android SDK, cross-platform - Ionic 1-3 (Cordova), JS, Angular JS.</p>
                    <div class="heading-block center">
                        <span>We bear full responsibility for the result</span>
                    </div>

                    <p>We comply with the regulations and are responsible for the quality and terms of our products. We conclude contracts that clearly specify all types and scope of work we perform, as well as the deadlines and final result for customers</p>
                    <div class="heading-block center">
                        <span>Honesty and transparency of cooperation</span>
                    </div>

                    <p>We are always in touch with our customers. At each stage of cooperation, we inform the customer about all ongoing operations concerning the creation of its project, give advices for making the best decision. At the initial stages of cooperation, we approve the cost of services, so our customers are always sure that the final invoice for the work performed will be fair and correct in accordance with the terms of reference, without any unreasonable additional figures.</p>
                    <div class="heading-block center">
                        <span>Only qualified and professional staff in the team</span>
                    </div>

                    <p>Together we create the functional and productive turnkey projects. Our team includes qualified specialists: project managers, frontend developers, backend developers, designers, performance enhancers and testers.</p>

                </div>
            </div>
            <ContactFragment />
        </div>
    </section>
</template>

<script>
    import ContactFragment from './../ContactFragment.vue'
    export default {
        name: "Solutions",

        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Solutions',
            // all titles will be injected into this template
            titleTemplate: '%s | Crafty Tech'
        },
        components: {
            ContactFragment,

        },
    }
</script>

<style scoped>
    .dark .fbox-bg.fbox-center .fbox-icon {
        background: transparent;
    }
</style>